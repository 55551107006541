import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "curated-material---the-business-of-games",
      "style": {
        "position": "relative"
      }
    }}>{`Curated Material - The Business of Games`}<a parentName="h1" {...{
        "href": "#curated-material---the-business-of-games",
        "aria-label": "curated material   the business of games permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <List mdxType="List">
      <Link to="https://medium.com/@polats/game-industry-timeline-from-1997-2018-4b5adfa76ecc" mdxType="Link">
        <h4 {...{
          "id": "game-industry-timeline-from-1997-to-2018",
          "style": {
            "position": "relative"
          }
        }}>{`Game Industry Timeline from 1997 to 2018`}<a parentName="h4" {...{
            "href": "#game-industry-timeline-from-1997-to-2018",
            "aria-label": "game industry timeline from 1997 to 2018 permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`A curated timeline of articles from Gamasutra showing each year's relevant technologies, issues, and industry market size. Links to the articles can be found `}<Link to="https://docs.google.com/spreadsheets/d/1NwNkNDqk31cKXO5e5vm3xWs4ieUU-sgot_7hu6f4pmg/" mdxType="Link">{`here.`}</Link></p>
      </Link>
      <Link to="https://medium.com/@polats/3-disruptive-game-design-trends-to-look-forward-to-in-2020-23c22aa4b6be" mdxType="Link">
        <h4 {...{
          "id": "3-disruptive-game-design-trends-to-look-forward-to-in-2020",
          "style": {
            "position": "relative"
          }
        }}>{`3 Disruptive Game Design Trends To Look Forward To In 2020`}<a parentName="h4" {...{
            "href": "#3-disruptive-game-design-trends-to-look-forward-to-in-2020",
            "aria-label": "3 disruptive game design trends to look forward to in 2020 permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`A slightly dated introspective of 2020. Begins with a look into the top mobile games of 2019, which has stayed the same for the past 4 years.`}</p>
      </Link>
      <Link to="https://www.flashgamehistory.com/" mdxType="Link">
        <h4 {...{
          "id": "flash-game-history",
          "style": {
            "position": "relative"
          }
        }}>{`Flash Game History`}<a parentName="h4" {...{
            "href": "#flash-game-history",
            "aria-label": "flash game history permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`A heartfelt tribute to one of the technologies that defined video games. Jonas Richner breaks down what made Flash so great, and how we can emulate its success.`}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      